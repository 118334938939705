// characters

export default {
  title: 'Users group',

  labels: {
    name: 'Name',
    appReserveAccess: 'App reservation access',
    reservableDate: 'Maximum booking date',
    active: 'Active',
    disable: 'Disable',
    day: 'day',
    week: 'week',
    month: 'month',
    delete: 'Delete group'
  },

  notifications: {
    insert: {
      success: 'Group information was successfully inserted',
      error: 'There was an error adding group information'
    },

    edit: {
      success: 'Group information successfully updated',
      error: 'Error editing group information'
    },

    delete: {
      success: 'The group information was deleted',
      error: 'Deleting group information has error',
      isUsedError: 'The group can not be deleted due to having a history of documents!'
    },

    restore: {
      success: 'The group was successfully restored',
      error: 'There was an error restoring the group'
    },

    parseError: {
      name: 'The selected name is invalid!',
      reservableDays: 'The number of bookable days is not entered correctly!'
    }
  },

  validators: {
    nameHelper: 'The name must be English!',
  },

  confirmations: {
    delete: {
      title: 'Group deletion confirmation',
      body: 'Are you sure you want to delete the {name} temporarily?'
    },

    forceDelete: {
      title: 'Group deletion confirmation',
      body: 'Are you sure you want to delete the {name} permanently?'
    }
  },

  table: {
    header: {
      row: 'Row',
      name: 'Name',
      createdDate: 'Create date',
      reservableDate: 'Maximum booking date',
      delete: 'Delete',
      restore: 'Restore'
    }
  },

  list: {
    title: 'Users group'
  },

  insert: {
    title: 'Insert new user group'
  },

  edit: {
    title: 'Edit user group',
    dynamicTitle: 'Edit {name} group'
  },

  profile: {
    title: '',
    dynamicTitle: '{name} profile',

    actions: {
      edit: 'Edit',
      usersList: '{name} users list',
      logs: 'Activity logs'
    }
  },

  select: {
    title: 'Users group list'
  },

  trash: {
    title: 'Removed users group'
  },

  user: {
    title: 'Group users list',
    dynamicTitle: '{name} group users list',

    labels: {
      delete: 'Delete user from group'
    },

    table: {
      header: {
        row: 'Row',
        name: 'Name and last name',
        phoneNumber: 'Phone number',
        delete: 'Delete'
      }
    },

    notifications: {
      notAllowed: 'It is not allowed to remove a user from the user group',
      userExist: 'This user is on the list',
      dynamicUserExist: '{name} exist in the list',

      insert: {
        success: 'Individuals in the target group were successfully registered',
        error: 'Registration of users in the desired group has error'
      },

      delete: {
        success: 'User was successfully removed from this group',
        error: 'Deleting a person from the group has error'
      }
    },

    confirmations: {
      delete: {
        title: 'Delete user from group confirmation',
        body: 'Are you sure you want to delete the {name}?'
      }
    },

    insert: {
      title: 'Add users to group'
    }
  },

  logs: {
    title: 'User groups Logs'
  }
}
